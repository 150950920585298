import ApiService from '@/services/core/api.service'

const FacebookRawDataService = {
  async get (query) {
    return ApiService.get(`user/facebook-raw-data?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/facebook-raw-data', payload)
  },

  async put (payload) {
    return ApiService.put(`user/facebook-raw-data/${payload.id}`, payload)
  }
}

export default FacebookRawDataService
