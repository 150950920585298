import ApiService from '@/services/core/api.service'

const VisitationPurposeService = {
  async get (query) {
    return ApiService.get(`admin/visitation-purposes?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/visitation-purposes', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/visitation-purposes/${payload.id}`, payload)
  }
}

export default VisitationPurposeService
