import ApiService from '@/services/core/api.service'

const FeedBrandService = {
  async get (query) {
    return ApiService.get(`admin/feed-brands?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/feed-brands', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/feed-brands/${payload.id}`, payload)
  }
}

export default FeedBrandService
