import * as types from '../mutation-types'

export const state = {
  user: null,
  token: null
}

export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null
}

export const mutations = {
  [types.SAVE_TOKEN] (state, token) {
    state.token = token
  },

  [types.FETCH_USER_SUCCESS] (state, user) {
    state.user = user
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.user = null
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token = null
  }
}

export const actions = {
  saveToken ({ commit }, token) {
    commit(types.SAVE_TOKEN, token)
  },

  fetchUserSuccess ({ commit }, user) {
    commit(types.FETCH_USER_SUCCESS, user)
  },

  fetchUserFailure ({ commit }) {
    commit(types.FETCH_USER_FAILURE)
  },

  logout ({ commit }) {
    commit(types.LOGOUT)
  }
}
