import ApiService from '@/services/core/api.service'

const LogService = {
  async getRawDataLogs (query) {
    return ApiService.get(`agent/raw-data-logs?${query}`)
  },

  async getCustomerLogs (query) {
    return ApiService.get(`agent/customer-logs?${query}`)
  }
}

export default LogService
