import ApiService from '@/services/core/api.service'

const MobilePrefixService = {
  async get (query) {
    return ApiService.get(`admin/mobile-prefixes?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/mobile-prefixes', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/mobile-prefixes/${payload.id}`, payload)
  }
}

export default MobilePrefixService
