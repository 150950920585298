import ApiService from '@/services/core/api.service'

const AgentService = {
  async get (query) {
    return ApiService.get(`lead-supervisor/agents?${query}`)
  },

  async post (payload) {
    return ApiService.post('lead-supervisor/agents', payload)
  },

  async put (payload) {
    return ApiService.put(`lead-supervisor/agents/${payload.id}`, payload)
  }
}

export default AgentService
