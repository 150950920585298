import ApiService from '@/services/core/api.service'

const VisitRequestService = {
  async get (query) {
    return ApiService.get(`agent/visit-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('agent/visit-requests', payload)
  },

  async put (payload) {
    return ApiService.put(`agent/visit-requests/${payload.id}`, payload)
  }
}

export default VisitRequestService
