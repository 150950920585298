import ApiService from '@/services/core/api.service'

const VisitRequestService = {
  async get (query) {
    return ApiService.get(`user/visit-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/visit-requests', payload)
  }
}

export default VisitRequestService
