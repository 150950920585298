import ApiService from '@/services/core/api.service'

const CallRequestService = {
  async get (query) {
    return ApiService.get(`user/call-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/call-requests', payload)
  },

  async put (payload) {
    return ApiService.put(`user/call-requests/${payload.id}`, payload)
  }
}

export default CallRequestService
