import ApiService from '@/services/core/api.service'

const ReportService = {
  async getCallLogs (query) {
    return ApiService.get(`supervisor/reports/call-logs?${query}`)
  },

  async getCallSummaries (query) {
    return ApiService.get(`supervisor/reports/call-summaries?${query}`)
  },

  async getConnectedSummaries (query) {
    return ApiService.get(`supervisor/reports/connected-summaries?${query}`)
  },

  async getUnsuccessfulSummaries (query) {
    return ApiService.get(`supervisor/reports/unsuccessful-summaries?${query}`)
  },

  async getPromotionSummaries (query) {
    return ApiService.get(`supervisor/reports/promotion-summaries?${query}`)
  },

  async getSMSSummaries (query) {
    return ApiService.get(`supervisor/reports/sms-summaries?${query}`)
  },

  async getSatisfactionSurvey (query) {
    return ApiService.get(`supervisor/reports/satisfaction-surveys?${query}`)
  }
}

export default ReportService
