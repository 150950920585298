import ApiService from '@/services/core/api.service'

const CampaignDataService = {
  async get (query) {
    return ApiService.get(`agent/campaign-data?${query}`)
  },

  async put (payload) {
    return ApiService.put(`agent/campaign-data/${payload.id}`, payload)
  },

  async delete (id) {
    return ApiService.delete(`agent/campaign-data/${id}`)
  }
}

export default CampaignDataService
