import ApiService from '@/services/core/api.service'

const SourceService = {
  async get (query) {
    return ApiService.get(`admin/sources?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/sources', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/sources/${payload.id}`, payload)
  }
}

export default SourceService
