import ApiService from '@/services/core/api.service'

const DealerService = {
  async get (query) {
    return ApiService.get(`agent/dealers?${query}`)
  },

  async post (payload) {
    return ApiService.post('agent/dealers', payload)
  },

  async put (payload) {
    return ApiService.put(`agent/dealers/${payload.id}`, payload)
  }
}

export default DealerService
