import ApiService from '@/services/core/api.service'

const DialectService = {
  async get (query) {
    return ApiService.get(`admin/dialects?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/dialects', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/dialects/${payload.id}`, payload)
  }
}

export default DialectService
