import store from '@/store'
import { SharedAuthService } from '@/services'

/* eslint-disable-next-line */
export default async (to, from, next) => {
  if (!store.getters['auth/check'] && store.getters['auth/token']) {
    await SharedAuthService.FetchUser().then(({ data }) => {
      store.dispatch('auth/fetchUserSuccess', data)
    }).catch(() => {
      store.dispatch('auth/fetchUserFailure')
    })
  }

  next()
}
