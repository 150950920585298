function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/shared/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const sharedRoutes = [
  {
    path: '',
    redirect: { name: 'shared.login' }
  },
  {
    path: '/sign-in',
    component: view('SignIn'),
    name: 'shared.login',
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/change-password',
    component: view('ChangePassword'),
    name: 'shared.change-password',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Change Password',
          active: true
        }
      ]
    }
  },
  {
    path: '/auth/:provider/callback',
    component: {
      template: '<div class="auth-component"></div>'
    },
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '*',
    component: view('404.vue'),
    meta: {
      layout: 'auth'
    }
  }
]

export default sharedRoutes
