import ApiService from '@/services/core/api.service'

const LogService = {
  async getCustomerLogs (query) {
    return ApiService.get(`shared/logs/customer-logs?${query}`)
  },

  async getVisitationLogs (query) {
    return ApiService.get(`shared/logs/visitation-logs?${query}`)
  },

  async getDealerLogs (query) {
    return ApiService.get(`shared/logs/dealer-logs?${query}`)
  }
}

export default LogService
