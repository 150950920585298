import ApiService from '@/services/core/api.service'

const ListService = {
  async getRegions () {
    return ApiService.get('shared/list/regions')
  },

  async getProvinces (query = '') {
    return ApiService.get(`shared/list/provinces?${query}`)
  },

  async getMunicipalities (query = '') {
    return ApiService.get(`shared/list/municipalities?${query}`)
  },

  async getUserProvinces (query = '') {
    return ApiService.get(`shared/list/user-provinces?${query}`)
  },

  async getUserMunicipalities (query = '') {
    return ApiService.get(`shared/list/user-municipalities?${query}`)
  },

  async getBarangays (query = '') {
    return ApiService.get(`shared/list/barangays?${query}`)
  },

  async getDistributions () {
    return ApiService.get('shared/list/distributions')
  },

  async getSources () {
    return ApiService.get('shared/list/sources')
  },

  async getDispositionTypes () {
    return ApiService.get('shared/list/disposition-types')
  },

  async getDispositions (query = '') {
    return ApiService.get(`shared/list/dispositions?${query}`)
  },

  async getPromotions () {
    return ApiService.get('shared/list/promotions')
  },

  async getUnsuccessfulReasons () {
    return ApiService.get('shared/list/unsuccessful-reasons')
  },

  async getAgents () {
    return ApiService.get('shared/list/agents')
  },

  async getLeadAgents () {
    return ApiService.get('shared/list/lead-agents')
  },

  async getResignedLeadAgents () {
    return ApiService.get('shared/list/resigned-lead-agents')
  },

  async getUsers () {
    return ApiService.get('shared/list/users')
  },

  async getUserGroups () {
    return ApiService.get('shared/list/user-groups')
  },

  async getDialects () {
    return ApiService.get('shared/list/dialects')
  },

  async getDealers () {
    return ApiService.get('shared/list/dealers')
  },

  async getCustomFields () {
    return ApiService.get('shared/list/custom-fields')
  },

  async getPartners () {
    return ApiService.get('shared/list/partners')
  },

  async getLeadTags () {
    return ApiService.get('shared/list/lead-tags')
  },

  async getOccupations () {
    return ApiService.get('shared/list/occupations')
  },

  async getLiveStocks () {
    return ApiService.get('shared/list/live-stocks')
  },

  async getLiveStockTypes (query) {
    return ApiService.get(`shared/list/live-stock-types?${query}`)
  },

  async getLiveStockCategories (query) {
    return ApiService.get(`shared/list/live-stock-categories?${query}`)
  },

  async getFeedBrands (query) {
    return ApiService.get(`shared/list/feed-brands?${query}`)
  },

  async getVisitationPurposes () {
    return ApiService.get('shared/list/visitation-purposes')
  }
}

export default ListService
