import ApiService from '@/services/core/api.service'

const ReportService = {
  async getVisitHistories (query) {
    return ApiService.get(`user/reports/visit-histories?${query}`)
  },

  async getCallRequestHistories (query) {
    return ApiService.get(`user/reports/call-request-histories?${query}`)
  }
}

export default ReportService
