import ApiService from '@/services/core/api.service'

const CallRequestService = {
  async get (query) {
    return ApiService.get(`agent/call-requests?${query}`)
  },

  async post (payload) {
    return ApiService.post('agent/call-requests', payload)
  },

  async put (payload) {
    return ApiService.put(`agent/call-requests/${payload.call_request_id}`, payload)
  },

  async delete (id) {
    return ApiService.post(`agent/call-requests/${id}`)
  }
}

export default CallRequestService
