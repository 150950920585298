import ApiService from '@/services/core/api.service'

const CampaignDataService = {
  async get (query) {
    return ApiService.get(`supervisor/campaign-data?${query}`)
  },

  async put (payload) {
    return ApiService.put(`supervisor/campaign-data/${payload.agent}`, payload)
  }
}

export default CampaignDataService
