import ApiService from '@/services/core/api.service'

const CustomFieldService = {
  async get (query) {
    return ApiService.get(`supervisor/custom-fields?${query}`)
  },

  async post (payload) {
    return ApiService.post('supervisor/custom-fields', payload)
  },

  async put (payload) {
    return ApiService.put(`supervisor/custom-fields/${payload.id}`, payload)
  }
}

export default CustomFieldService
