function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/users',
    component: view('Users'),
    name: 'admin.users',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/accounts',
    component: view('UserAccounts'),
    name: 'admin.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/lead-agents',
    component: view('LeadAgents'),
    name: 'admin.lead-agents',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Lead Agents',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/dealers',
    component: view('Dealers'),
    name: 'admin.dealers',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Dealers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/utilities/sms',
    component: view('UtilitySMS'),
    name: 'admin.sms',
    meta: {
      breadcrumb: [
        {
          text: 'Utilities'
        },
        {
          text: 'SMS',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/customers',
    component: view('Customers'),
    name: 'admin.customers',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Customers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/raw-data',
    component: view('RawData'),
    name: 'admin.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/regions',
    component: view('Regions'),
    name: 'admin.regions',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Regions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/provinces',
    component: view('Provinces'),
    name: 'admin.provinces',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Provinces',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/municipalities',
    component: view('Municipalities'),
    name: 'admin.municipalities',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Municipalities',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/barangays',
    component: view('Barangays'),
    name: 'admin.barangays',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Barangays',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/distributions',
    component: view('Distributions'),
    name: 'admin.distributions',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Distributions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/mobile-prefixes',
    component: view('MobilePrefixes'),
    name: 'admin.mobile-prefixes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Mobile Prefixes',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/dialects',
    component: view('Dialects'),
    name: 'admin.dialects',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Dialects',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/sources',
    component: view('Sources'),
    name: 'admin.sources',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Sources'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/occupations',
    component: view('Occupations'),
    name: 'admin.occupations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Occupations'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/visitation-purposes',
    component: view('VisitationPurposes'),
    name: 'admin.visitation-purposes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Visitation Purposes'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/survey-questionares',
    component: view('SurveyQuestionares'),
    name: 'admin.survey-questionares',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Survey Questionares'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/lead-tags',
    component: view('LeadTags'),
    name: 'admin.leadtags',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Lead Tags'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/live-stocks',
    component: view('LiveStocks'),
    name: 'admin.livestocks',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Live Stocks'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/live-stock-types',
    component: view('LiveStockTypes'),
    name: 'admin.livestocktypes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Live Stock Types'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/live-stock-categories',
    component: view('LiveStockCategories'),
    name: 'admin.livestockcategories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Live Stock Categories'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/feed-brands',
    component: view('FeedBrands'),
    name: 'admin.feedbrands',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Feed Brands'
        }
      ]
    }
  }
]

export default adminRoutes
