function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/lead-supervisor/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const leadSupervisorRoutes = [
  {
    path: '/lead-supervisor/dashboard',
    component: view('Dashboard'),
    name: 'lead-supervisor.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/lead-supervisor/visit-requests',
    component: view('VisitRequests'),
    name: 'lead-supervisor.visit-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Visit Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/lead-supervisor/raw-data',
    component: view('RawData'),
    name: 'lead-supervisor.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/lead-lead-supervisor/master-data',
    component: view('MasterData'),
    name: 'lead-supervisor.master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/lead-supervisor/maintenances/agents',
    component: view('Agents'),
    name: 'lead-supervisor.agents',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Agents',
          active: true
        }
      ]
    }
  }
]

export default leadSupervisorRoutes
