import ApiService from '@/services/core/api.service'

const AuthService = {
  async SignIn (payload) {
    return ApiService.post('auth/login', payload)
  },

  async FetchUser () {
    return ApiService.get('auth/me')
  },

  async ChangePassword (payload) {
    return ApiService.post('auth/password', payload)
  },

  async Logout () {
    return ApiService.post('auth/logout')
  }
}

export default AuthService
