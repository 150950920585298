import ApiService from '@/services/core/api.service'

const UtilitySMSService = {
  async get (query) {
    return ApiService.get(`admin/utility-sms?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/utility-sms', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/utility-sms/${payload.id}`, payload)
  }
}

export default UtilitySMSService
