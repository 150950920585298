function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/supervisor/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const supervisorRoutes = [
  {
    path: '/supervisor/dashboard',
    component: view('Dashboard'),
    name: 'supervisor.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/raw-data',
    component: view('RawData'),
    name: 'supervisor.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/campaign-data',
    component: view('CampaignData'),
    name: 'supervisor.campaign-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Campaign Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/master-data',
    component: view('MasterData'),
    name: 'supervisor.master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/disposition-types',
    component: view('DispositionTypes'),
    name: 'supervisor.disposition-types',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Disposition Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/dispositions',
    component: view('Dispositions'),
    name: 'supervisor.dispositions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Dispositions',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/unsuccessful-reasons',
    component: view('UnsuccessfulReasons'),
    name: 'supervisor.unsuccessful-reasons',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Unsuccessful Reasons',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/promotions',
    component: view('Promotions'),
    name: 'supervisor.promotions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Promotions',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/campaigns',
    component: view('Campaigns'),
    name: 'supervisor.campaigns',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Campaigns',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/custom-fields',
    component: view('CustomFields'),
    name: 'supervisor.custom-fields',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Custom Fields',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/agent-groups',
    component: view('AgentGroups'),
    name: 'supervisor.agent-groups',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Agent Groups',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/agents',
    component: view('Agents'),
    name: 'supervisor.agents',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Agents',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/sms-summaries',
    component: view('ReportSMSSummaries'),
    name: 'supervisor.report-sms-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'SMS Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/call-logs',
    component: view('ReportCallLogs'),
    name: 'supervisor.report-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/call-summaries',
    component: view('ReportCallSummaries'),
    name: 'supervisor.report-call-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/connected-summaries',
    component: view('ReportConnectedSummaries'),
    name: 'supervisor.report-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/unsuccessful-summaries',
    component: view('ReportUnsuccessfulSummaries'),
    name: 'supervisor.report-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/promotion-summaries',
    component: view('ReportPromotionSummaries'),
    name: 'supervisor.report-promotion-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Promotion Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/reports/satisfaction-surveys',
    component: view('ReportFarmerSatisfactionSurvey'),
    name: 'supervisor.report-satisfaction-surveys',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Satisfaction Surveys',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/general-information',
    component: view('StatGeneralInformation'),
    name: 'supervisor.statistics-general-information',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Genetal Information',
          active: true
        }
      ]
    }
  }
]

export default supervisorRoutes
