import ApiService from '@/services/core/api.service'

const DispositionService = {
  async get (query) {
    return ApiService.get(`supervisor/dispositions?${query}`)
  },

  async post (payload) {
    return ApiService.post('supervisor/dispositions', payload)
  },

  async put (payload) {
    return ApiService.put(`supervisor/dispositions/${payload.id}`, payload)
  }
}

export default DispositionService
