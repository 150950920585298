function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/agent/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const AgentRoutes = [
  {
    path: '/agent/dashboard',
    component: view('Dashboard'),
    name: 'agent.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/pending-calls',
    component: view('PendingCalls'),
    name: 'agent.pending-calls',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Pending Calls',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/visit-requests',
    component: view('VisitRequests'),
    name: 'agent.visit-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Visit Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/pending-data',
    component: view('PendingData'),
    name: 'agent.pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/invalid-pending-data',
    component: view('InvalidPendingData'),
    name: 'agent.invalid-pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Invalid Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/campaign-tracker',
    component: view('CampaignTracker'),
    name: 'agent.campaign-tracker',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Campaign Tracker',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/campaign-data',
    component: view('CampaignData'),
    name: 'agent.campaign-data',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Campaign Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/master-data',
    component: view('MasterData'),
    name: 'agent.master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Raisers'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/dealers/master-data',
    component: view('Dealers'),
    name: 'agent.dealers-master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Dealers'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/facebook/pending-data',
    component: view('FacebookRaw'),
    name: 'agent.facebook-pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Facebook'
        },
        {
          text: 'Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/facebook/leads',
    component: view('FacebookLeads'),
    name: 'agent.facebook-leads',
    meta: {
      breadcrumb: [
        {
          text: 'Facebook'
        },
        {
          text: 'Leads',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/utilities/sms',
    component: view('UtilitySMS'),
    name: 'agent.sms',
    meta: {
      breadcrumb: [
        {
          text: 'Utilities'
        },
        {
          text: 'SMS',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/call-logs',
    component: view('ReportCallLogs'),
    name: 'agent.report-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/connected-summaries',
    component: view('ReportConnectedSummaries'),
    name: 'agent.report-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/unsuccessful-summaries',
    component: view('ReportUnsuccessfulSummaries'),
    name: 'agent.report-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/promotion-summaries',
    component: view('ReportPromotionSummaries'),
    name: 'agent.report-promotion-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Promotion Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/visit-request-histories',
    component: view('ReportVisitRequestHistories'),
    name: 'agent.report-visit-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Visit Request Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/call-request-histories',
    component: view('ReportCallRequestHistories'),
    name: 'agent.report-call-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Request Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/sms-summaries',
    component: view('ReportSMSSummaries'),
    name: 'agent.report-sms-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'SMS Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/satisfaction-surveys',
    component: view('ReportSatisfactionSurveys'),
    name: 'agent.report-satisfaction-surveys',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Satisfaction Surveys',
          active: true
        }
      ]
    }
  }
]

export default AgentRoutes
