import ApiService from '@/services/core/api.service'

const DashboardService = {
  async getStatistics () {
    return ApiService.get('agent/dashboard/statistics')
  },

  async releaseCampaign () {
    return ApiService.post('agent/dashboard/release-campaign')
  }
}

export default DashboardService
