import ApiService from '@/services/core/api.service'

const UserAccountsService = {
  async get (query) {
    return ApiService.get(`admin/user-accounts?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/user-accounts', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/user-accounts/${payload.id}`, payload)
  }
}

export default UserAccountsService
