import Vue from 'vue'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'

const options = {
  providers: {
    oauth2: {
      clientId: `${process.env.VUE_APP_SSO_CLIENT_ID}`,
      authorizationEndpoint: `${process.env.VUE_APP_SSO_URL}`,
      redirectUri: `${window.location.origin}/auth/oauth2/callback`
    }
  }
}

const Oauth = new UniversalSocialauth(axios, options)

Vue.prototype.$Oauth = Oauth
