import ApiService from '@/services/core/api.service'

const PendingDataService = {
  async get (query) {
    return ApiService.get(`agent/pending-data?${query}`)
  },

  async post (payload) {
    return ApiService.post('agent/pending-data', payload)
  },

  async put (payload) {
    return ApiService.put(`agent/pending-data/${payload.id}`, payload)
  }
}

export default PendingDataService
