import store from '@/store'

export default async (to, from, next) => {
  const user = store.getters['auth/user']
  const routeName = `${user.role}.dashboard`
  if (user.role !== 'agent') {
    next({ name: routeName })
  } else {
    if (to.name === 'agent.upload-raw-data') {
      if (user.is_officer === 1) {
        next()
      } else {
        next({ name: routeName })
      }
    } else if (to.name === 'agent.custom_field') {
      if (user.is_head === 1) {
        next()
      } else {
        next({ name: routeName })
      }
    } else {
      next()
    }
  }
}
