import ApiService from '@/services/core/api.service'

const CampaignService = {
  async get (query) {
    return ApiService.get(`supervisor/campaigns?${query}`)
  },

  async post (payload) {
    return ApiService.post('supervisor/campaigns', payload)
  },

  async put (payload) {
    return ApiService.put(`supervisor/campaigns/${payload.id}`, payload)
  }

}

export default CampaignService
