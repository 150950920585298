function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/raw-data',
    component: view('RawData'),
    name: 'user.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/facebook-raw-data',
    component: view('FacebookRawData'),
    name: 'user.facebook-raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Facebook Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/my-leads',
    component: view('MyLeads'),
    name: 'user.my-leads',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'My Leads',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/pending-visitations',
    component: view('PendingVisitations'),
    name: 'user.pending-visitations',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Visitations',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/call-requests',
    component: view('CallRequests'),
    name: 'user.call-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Call Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/reports/visit-histories',
    component: view('ReportVisitHistories'),
    name: 'user.report-visit-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Visit Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/reports/call-request-histories',
    component: view('ReportCallRequestHistories'),
    name: 'user.report-call-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Request Histories',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
