export const state = {
  horizontalMenu: false,
  miniSidebarMenu: false,
  activePage: {},
  layoutMode: {
    dark: false,
    rtl: false
  }
}

export const getters = {
  horizontalMenuState: state => state.horizontalMenu,
  miniSidebarState: state => state.miniSidebarMenu,
  activePage: state => state.activePage,
  darkModeState: state => state.layoutMode.dark,
  rtlModeState: state => state.layoutMode.rtl
}

export const actions = {
  horizontalMenuAction (context, payload) {
    if (context.state.horizontalMenu) {
      context.commit('horizontalMenuCommit', false)
    } else {
      context.commit('horizontalMenuCommit', true)
    }
  },
  miniSidebarAction (context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.miniSidebarMenu) {
        context.commit('miniSidebarCommit', false)
        resolve(false)
      } else {
        context.commit('miniSidebarCommit', true)
        resolve(true)
      }
    })
  },
  activePageAction (context, payload) {
    context.commit('activePageCommit', payload)
  },
  layoutModeAction (context, payload) {
    if (payload.dark) {
      document.querySelector('body').classList.add('dark')
    } else {
      document.querySelector('body').classList.remove('dark')
    }

    document.getElementsByTagName('html')[0].setAttribute('dir', payload.rtl ? 'rtl' : 'ltr')
    context.commit('layoutModeCommit', {
      dark: payload.dark,
      rtl: payload.rtl
    })
  }
}

export const mutations = {
  horizontalMenuCommit (state, data) {
    state.horizontalMenu = data
  },
  miniSidebarCommit (state, data) {
    state.miniSidebarMenu = data
  },
  activePageCommit (state, data) {
    state.activePage = data
  },
  layoutModeCommit (state, data) {
    state.layoutMode = data
  }
}
