
import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import { SharedValidationService } from '@/services'

localize({
  en: {
    messages: {
      required: field => {
        return `The ${field} field is required`
      },

      email: field => {
        return `The ${field} must be a valid email address`
      },

      unique: field => {
        return `The ${field} has already been taken`
      }
    }
  }
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('unique', {
  validate: async (value, args) => {
    let ignoreId = 0

    if (args.length > 0) {
      ignoreId = parseInt(args[0])
    }

    if (value.length < 11) {
      return true
    }

    const response = await SharedValidationService.isMobileUnique({
      mobile_number: value,
      ignore_id: ignoreId
    })

    if (response.data.valid) {
      return true
    }

    return {
      valid: false
    }
  }
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
