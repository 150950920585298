import ApiService from '@/services/core/api.service'

const MasterDataService = {
  async get (query) {
    return ApiService.get(`lead-supervisor/master-data?${query}`)
  },

  async post (payload) {
    return ApiService.post('lead-supervisor/master-data', payload)
  },

  async put (payload) {
    return ApiService.put(`lead-supervisor/master-data/${payload.id}`, payload)
  }
}

export default MasterDataService
