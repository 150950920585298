import ApiService from '@/services/core/api.service'

const ImportService = {
  async importCustomers (payload) {
    return ApiService.post('admin/import/customers', payload)
  },

  async importDealers (payload) {
    return ApiService.post('admin/import/dealers', payload)
  }
}

export default ImportService
