import sharedRoutes from './shared-routes'
import userRoutes from './user-routes'
import agentRoutes from './agent-routes'
import supervisorRoutes from './supervisor-routes'
import leadSupervisorRoutes from './lead-supervisor-routes'
import managerRoutes from './manager-routes'
import adminRoutes from './admin-routes'

const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  userRoutes,
  agentRoutes,
  supervisorRoutes,
  leadSupervisorRoutes,
  managerRoutes
)
