import ApiService from '@/services/core/api.service'

const CustomerService = {
  async get (query) {
    return ApiService.get(`admin/customers?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/customers', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/customers/${payload.id}`, payload)
  }
}

export default CustomerService
