import ApiService from '@/services/core/api.service'

const AgentService = {
  async get (query) {
    return ApiService.get(`admin/lead-agents?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/lead-agents', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/lead-agents/${payload.id}`, payload)
  }
}

export default AgentService
